import { useEffect, useMemo, useState } from "react";
import { saveAs } from "file-saver";
import { exportDB, importDB } from "dexie-export-import";
import { localData } from "../../../services/localData";
import { AttachmentParentType, type Attachment } from "../../../models/attachments/Attachment";
import { Button } from "../../components/input/Button";
import { getAuthUser } from "../../../helpers/AuthenticationHelpers";
import {
	type QuestionnaireTemplate,
	type StoredQuestionnaireTemplate,
} from "../../../models/questionnaire";
import { ConfirmationModal, NotificationModal } from "../../components/modals";
import { getApplicationId } from "../../utilities/ApplicationId";
import { logException } from "../../../services/log";
import {
	generateFileName,
	generateFormZipFile,
	loadFormFromZipFile,
} from "./helpers/auditPageHelpers";
import { DisplayObjectModal } from "./components/DisplayObjectModal/DisplayObjectModal";
import type { Portal } from "../../../models/portal";
import type { User } from "oidc-client-ts";
import "./AuditPage.styles.scss";

const AuditPage = () => {
	const [portals, setPortals] = useState<Portal[]>([]);
	const [formTemplates, setFormTemplates] = useState<QuestionnaireTemplate[]>([]);
	const [forms, setForms] = useState<StoredQuestionnaireTemplate[]>([]);
	const [attachments, setAttachments] = useState<Attachment[]>([]);
	const [authUser, setAuthUser] = useState<User | null>(null);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [showImportDatabaseModal, setShowImportDatabaseModal] = useState(false);
	const [databaseImportFile, setDatabaseImportFile] = useState<File | null>(null);
	const applicationId = useMemo(() => getApplicationId(), []);

	const loadData = async () => {
		if (!localData.isOpen()) {
			localData.open();
		}
		await Promise.all([
			localData.portals.toArray().then((portals) => setPortals(portals)),
			localData.questionnaireTemplates
				.toArray()
				.then((formTemplates) => setFormTemplates(formTemplates)),
			localData.questionnaires.toArray().then((forms) => setForms(forms)),
			localData.attachments.toArray().then((attachments) => setAttachments(attachments)),
			getAuthUser().then((authUser) => setAuthUser(authUser)),
		]).catch((error) => {
			logException(error);
			setError("There was an error retrieving local data.");
			setShowErrorModal(true);
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	const exportAll = async () => {
		try {
			const dbBlob = await exportDB(localData, {
				prettyJson: true,
			});
			saveAs(dbBlob, generateFileName("audit", "json"));
		} catch (error) {
			logException(error);
			setError("There was an issue downloading the data.");
			setShowErrorModal(true);
		}
	};

	const importAll = async () => {
		if (!databaseImportFile) {
			return;
		}
		try {
			await localData.delete();
			await importDB(databaseImportFile);
			await loadData();
		} catch (error) {
			logException(error);
			setError("There was an issue importing the data.");
			setShowErrorModal(true);
			closeImportDatabaseConfirmModal();
		}
		closeImportDatabaseConfirmModal();
	};

	const openImportDatabaseConfirmModal = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files || event.target.files.length === 0) {
			event.target.value = "";
			return;
		}
		const file = event.target.files[0];
		setDatabaseImportFile(file);
		setShowImportDatabaseModal(true);
		event.target.value = "";
	};

	const closeImportDatabaseConfirmModal = () => {
		setDatabaseImportFile(null);
		setShowImportDatabaseModal(false);
	};

	const exportForm = async (guid: string, name: string) => {
		try {
			const generated = await generateFormZipFile(guid);
			if (!generated) {
				throw new Error("There was an issue generating the form zip file.");
			}
			saveAs(generated, generateFileName(name, "zip"));
		} catch (error) {
			logException(error);
			setError("There was an issue downloading the form.");
			setShowErrorModal(true);
		}
	};

	const importForm = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files || event.target.files.length === 0) {
			event.target.value = "";
			return;
		}
		try {
			await loadFormFromZipFile(event.target.files[0]);
			await loadData();
		} catch (error) {
			setError("There was an issue importing the form.");
			setShowErrorModal(true);
			logException(error);
		}

		event.target.value = "";
	};

	const closeErrorModal = () => {
		setError("");
		setShowErrorModal(false);
		loadData();
	};

	return (
		<main className="audit-page">
			<p className="audit-page__warning">
				WARNING: This tool is for authorized use only. It should be operated exclusively
				under the instruction of Assure administrators or Evotix staff.
			</p>
			<div className="audit-page__user-info">
				Application ID: {applicationId}, User ID: {authUser?.profile.sub ?? "anonymous"}
			</div>
			<h1>IndexedDB Audit</h1>
			<div className="audit-page__button-group">
				<Button onClick={exportAll}>Download Full Database</Button>
				<div className="basic-file-input">
					<div>
						<input
							accept=".json"
							className="basic-file-input__input"
							id="file-input-import-all"
							onChange={openImportDatabaseConfirmModal}
							type="file"
						/>
						<label
							className="basic-file-input__label she-btn she-btn-tertiary"
							htmlFor="file-input-import-all"
						>
							Import Database
						</label>
					</div>
				</div>
				<div className="basic-file-input">
					<div>
						<input
							accept=".zip"
							className="basic-file-input__input"
							id="file-input-import-form"
							onChange={importForm}
							type="file"
						/>
						<label
							className="basic-file-input__label she-btn she-btn-tertiary"
							htmlFor="file-input-import-form"
						>
							Import Form
						</label>
					</div>
				</div>
				<Button onClick={loadData}>Refresh</Button>
			</div>
			<div>
				<h2>Forms</h2>
				{forms.length === 0 ? (
					<div>No forms found</div>
				) : (
					<ol>
						{forms.map((form) => (
							<li key={form.questionnaire.id}>
								<h3>
									{form.questionnaire.name}
									<span>{form.questionnaire.templateId}</span>
								</h3>
								<div>
									<span>{form.status.questionnaireState}</span>
									<span>{form.questionnaire.portalKey}</span>
								</div>
								{form.status.dateCreated ? (
									<div>Created: {form.status.dateCreated.toString()}</div>
								) : null}
								{form.status.dateCompleted ? (
									<div>{form.status.dateCompleted.toString()}</div>
								) : null}
								{form.userId ? <div>User ID: {form.userId}</div> : null}
								<div>Id: {form.questionnaire.id}</div>
								<div className="audit-page__button-group">
									{!form.userId || authUser?.profile.sub === form.userId ? (
										<Button
											onClick={() =>
												exportForm(
													form.questionnaire.id,
													form.questionnaire.name,
												)
											}
										>
											Download
										</Button>
									) : null}
									<DisplayObjectModal objectContent={form} />
								</div>
							</li>
						))}
					</ol>
				)}
			</div>
			<div>
				<h2>Attachments</h2>
				{attachments.length === 0 ? (
					"No attachments found."
				) : (
					<ol>
						{attachments.map((attachment) => (
							<li key={attachment.id}>
								<h3>{attachment.fileName}</h3>
								<div>Record Id: {attachment.recordId}</div>
								<div>{AttachmentParentType[attachment.parentType]}</div>
								{attachment.parentGuid ? <div>{attachment.parentGuid}</div> : null}
								<DisplayObjectModal objectContent={attachment} />
							</li>
						))}
					</ol>
				)}
			</div>
			<div>
				<h2>Portals</h2>
				{portals.length === 0 ? (
					"No portals found."
				) : (
					<ol>
						{portals.map((portal) => (
							<li key={portal.key}>
								<h3>{portal.title}</h3>
								<div>{portal.key}</div>
								<div>{portal.customerKey}</div>
								<DisplayObjectModal objectContent={portal} />
							</li>
						))}
					</ol>
				)}
			</div>
			<div>
				<h2>Form Templates</h2>
				{formTemplates.length === 0 ? (
					"No form templates found."
				) : (
					<ol>
						{formTemplates.map((formTemplate) => (
							<li key={formTemplate.questionnaire.id}>
								<h3>{formTemplate.questionnaire.name}</h3>
								<div>{formTemplate.questionnaire.portalKey}</div>
								<DisplayObjectModal objectContent={formTemplate} />
							</li>
						))}
					</ol>
				)}
			</div>

			<NotificationModal content={error} onClose={closeErrorModal} show={showErrorModal} />
			<ConfirmationModal
				confirmText="Yes"
				onCancel={closeImportDatabaseConfirmModal}
				onConfirm={() => {
					importAll();
				}}
				show={showImportDatabaseModal}
				text="Importing a database will overwrite all existing data. Are you sure you want to continue?"
			/>
		</main>
	);
};

export { AuditPage };
